import debounce from './common/debounce.es6';
import { get } from './common/index.es6';
import { isModifierKey, getKeyCode } from './common/util.es6';

const { tineAPI } = window;

const DEBOUNCED_TIMEOUT = 350;
let selectedResultIndex = -1;

const selectedOptionClass = 'selected-option';

(() => {
	setupAutoCompleteEvent();
	setupSubmitListener();
})();

const getSearchSuggestions = debounce((searchInput, autoCompleteList, formDomNode) => {
	const url = `${tineAPI.completionUrl}?q=${encodeURIComponent(searchInput.value.trim())}`;
	return get(url, null, {
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		}
	})
		.then(response => response.json())
		.then((completionList) => {
			renderCompletionList(completionList, autoCompleteList, searchInput, formDomNode);
			resetSelection(formDomNode);
		});
}, DEBOUNCED_TIMEOUT);

function getSearchInput(formDomNode) {
	return formDomNode.querySelector('input.js-search-input');
}

function setupAutoCompleteEvent() {
	const searchForms = [...document.querySelectorAll('form.js-search-form')];
	searchForms.forEach((formDomNode) => {
		const searchInput = getSearchInput(formDomNode);
		const autoCompleteList = formDomNode.querySelector('div.js-autocomplete-list');
		if (autoCompleteList) {
			setupAutcompleteDismiss(autoCompleteList, formDomNode);
		}

		function onKeyDown(event) {
			if (isModifierKey(event) || event.which === getKeyCode('LEFT') || event.which === getKeyCode('RIGHT') || event.which === getKeyCode('ENTER')) {
				return event; // Do nothing if is modifier key to allow e.g. SHIFT-tabing out of the search form.
			}
			if (event.which === getKeyCode('ESCAPE') || event.which === getKeyCode('TAB')) {
				return hideAutoComplete(autoCompleteList, formDomNode);
			}
			if (event.which === getKeyCode('DOWN') || event.which === getKeyCode('UP')) {
				if (autoCompleteList.classList.contains('hidden')) {
					return getSearchSuggestions(searchInput, autoCompleteList, formDomNode, true);
				}
				return moveSelection(event, formDomNode);
			}
			resetSelection(formDomNode);
			return getSearchSuggestions(searchInput, autoCompleteList, formDomNode);
		}
		searchInput.addEventListener('keydown', onKeyDown);
	});
}

function resetSelection(formDomNode) {
	const selectedOption = formDomNode.querySelector(`.${selectedOptionClass}`);
	deselect(selectedOption, formDomNode);
	selectedResultIndex = -1;
}

function moveSelection(event, formDomNode) {
	event.preventDefault();
	const options = [...formDomNode.querySelectorAll('.js-autocomplete-inner-list .m-search__autocomplete-inner-item')]
		.filter(option => window.getComputedStyle(option).display !== 'none');
	const numberOfResults = options.length || 0;

	if (selectedResultIndex >= 0) {
		const previouslySelectedOption = formDomNode.querySelector(`.${selectedOptionClass}`);
		deselect(previouslySelectedOption, formDomNode);
	}
	const indexOfLastOption = numberOfResults - 1;
	if (event.which === getKeyCode('DOWN')) {
		if (selectedResultIndex < indexOfLastOption) {
			selectedResultIndex += 1;
		} else {
			selectedResultIndex = 0;
		}
	} else if (event.which === getKeyCode('UP') && selectedResultIndex <= 0) {
		selectedResultIndex = numberOfResults - 1;
	} else {
		selectedResultIndex -= 1;
	}

	const optionToFocus = options[selectedResultIndex];
	select(optionToFocus, formDomNode);
	return event;
}

function renderCompletionList(list, domElement, inputElement, formDomNode) {
	let fullOptionsList = [];
	const trimmedInputvalue = inputElement.value.trim();
	const listboxId = formDomNode.querySelector('div[role="listbox"]').id;
	if (trimmedInputvalue.length) {
		const comboboxElement = formDomNode.querySelector('div[role="combobox"]');
		comboboxElement.setAttribute('aria-expanded', true);
		inputElement.setAttribute('aria-controls', listboxId);
		fullOptionsList = [
			{ text: trimmedInputvalue, highlightedText: trimmedInputvalue },
			...list
		];
		domElement.classList.remove('hidden');
	} else {
		hideAutoComplete(domElement, formDomNode);
	}

	const inner = domElement.querySelector('.js-autocomplete-inner-list');
	inner.innerHTML = fullOptionsList.reduce((memo, item, index) => {
		const isFirstOption = index === 0;
		const listItem = `<div class="m-search__autocomplete-inner-item" aria-selected="false"  role="option" id="${listboxId}-option-${index}">
			<a href="${tineAPI.searchUrl}?q=${item.text}">
				<span>
					${isFirstOption ? '<span class="icon-search icon-search-option"></span>' : ''}
					${isFirstOption ? '<em>' : ''}
					${item.highlightedText}
					${isFirstOption ? '</em>' : ''}
				</span>
			</a>
		</div>`;
		return memo + listItem;
	}, '');
}

function setupAutcompleteDismiss(autoCompleteList, formDomNode) {
	document.addEventListener('click', () => hideAutoComplete(autoCompleteList, formDomNode));
	autoCompleteList.addEventListener('click', e => e.stopPropagation());
}

function hideAutoComplete(autoCompleteList, formDomNode) {
	const previouslySelectedOption = formDomNode.querySelector(`.${selectedOptionClass}`);
	deselect(previouslySelectedOption, formDomNode);
	const comboboxElement = formDomNode.querySelector('div[role="combobox"]');
	comboboxElement.setAttribute('aria-expanded', false);
	const searchInput = getSearchInput(formDomNode);
	searchInput.removeAttribute('aria-controls');
	searchInput.removeAttribute('aria-activedescendant');
	if (!autoCompleteList.classList.contains('hidden')) {
		autoCompleteList.classList.add('hidden');
	}
}

function select(element, formDomNode) {
	const searchInput = getSearchInput(formDomNode);
	if (element) {
		element.setAttribute('aria-selected', true);
		element.classList.add(selectedOptionClass);
		searchInput.value = element.innerText.trim();
		searchInput.setAttribute('aria-activedescendant', element.id);
	}
}

function deselect(element, formDomNode) {
	if (element) {
		element.setAttribute('aria-selected', false);
		element.classList.remove(selectedOptionClass);
		getSearchInput(formDomNode).removeAttribute('aria-activedescendant');
	}
}

function setupSubmitListener() {
	const searchForms = [...document.querySelectorAll('form.js-search-form')];
	searchForms.forEach((searchForm) => {
		searchForm.addEventListener('submit', (event) => {
			event.stopPropagation();
			event.preventDefault();
			submitForm(searchForm);
		});
	});
}

export function submitForm(form) {
	form.submit();
}
