const selectedOptionClass = 'selected-option';
const keyCodeLists = {
	ALT: 18,
	ESCAPE: 27,
	TAB: 9,
	ENTER: 13,
	LEFT: 37,
	UP: 38,
	RIGHT: 39,
	DOWN: 40,
	CMD: 91,
	CTRL: 17,
	SHIFT: 16
};

export function toggleClassOnElement(className, element) {
	if (element.classList.contains(className)) {
		element.classList.remove(className);
	} else {
		element.classList.add(className);
	}
}

export function toggleClassOnElementSelector(className, elementSelector) {
	const element = document.querySelector(elementSelector);
	toggleClassOnElement(className, element);
}

export function toggleAriaExpanded(accordion) {
	if (accordion.getAttribute('aria-expanded') === 'true') {
		accordion.setAttribute('aria-expanded', false);
	} else {
		accordion.setAttribute('aria-expanded', true);
	}
}

export function isModifierKey(event) {
	switch (event.which) {
		case keyCodeLists.SHIFT:
		case keyCodeLists.ALT:
		case keyCodeLists.CTRL:
		case keyCodeLists.CMD:
			return true;
		default:
			return false;
	}
}

export function getKeyCode(codename) {
	return keyCodeLists[codename];
}

export function toggleSelection(element) {
	if (!element) return;
	if (element.getAttribute('aria-selected') === 'true') {
		element.setAttribute('aria-selected', false);
		element.classList.remove('selected-option');
	} else {
		element.setAttribute('aria-selected', true);
		element.classList.add('selected-option');
	}
}

export function handleKeyNavigation(e, dropdown, list, btn, selectedResultIndex) {
	if (e.which === keyCodeLists.ENTER && btn) {
		const sortListItems = [...list.children];

		sortListItems.forEach((selectedItem) => {
			if (selectedItem.classList && selectedItem.classList.contains('selected-option')) {
				btn.innerHTML = selectedItem.innerHTML;

				if (selectedItem.getAttribute('aria-selected') === 'true') {
					const link = selectedItem.children[0];
					e.preventDefault();
					link.click();
				}
			}
		});
		toggleAriaExpanded(btn);
	}

	if ((e.which === keyCodeLists.ESCAPE || e.which === keyCodeLists.TAB) && btn) {
		return hideList(list, btn, dropdown);
	}

	if (e.which === keyCodeLists.DOWN || e.which === keyCodeLists.UP) {
		return moveSelection(e, dropdown, list, selectedResultIndex);
	}

	return resetSelection(dropdown);
}

function moveSelection(event, dropdown, list, selectedResultIndex) {
	event.preventDefault();

	let newSelectedIndex = selectedResultIndex;
	const children = [...list.children];
	const indexOfLastOption = children.length - 1;

	if (selectedResultIndex >= 0) {
		const previouslySelectedOption = dropdown.querySelector(`.${selectedOptionClass}`);
		toggleSelection(previouslySelectedOption);
	}

	if (event.which === keyCodeLists.DOWN) {
		newSelectedIndex = selectedResultIndex < indexOfLastOption ? selectedResultIndex + 1 : 0;
	}

	if (event.which === keyCodeLists.UP) {
		newSelectedIndex = selectedResultIndex <= 0 ? indexOfLastOption : selectedResultIndex - 1;
	}

	const optionToFocus = children[newSelectedIndex];
	const selectedOption = document.querySelector(`.${selectedOptionClass}`);
	if (selectedOption) toggleSelection(selectedOption, selectedOptionClass);
	toggleSelection(optionToFocus, selectedOptionClass);

	return newSelectedIndex;
}

function resetSelection(dropdown) {
	const selectedOption = dropdown.querySelector(`.${selectedOptionClass}`);
	toggleSelection(selectedOption);
	return -1;
}

function hideList(sortList, selectButton, dropdown) {
	const selectedOptionClass = 'selected-option';
	const previouslySelectedOption = dropdown.querySelector(`.${selectedOptionClass}`);
	toggleSelection(previouslySelectedOption);
	selectButton.setAttribute('aria-expanded', false);

	if (!sortList.classList.contains('dropdown-custom__hidden')) {
		sortList.classList.add('dropdown-custom__hidden');
	}
}
