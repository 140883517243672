import { toggleSelection, handleKeyNavigation, toggleClassOnElementSelector, toggleAriaExpanded } from './common/util.es6';

function setupSortListListeners() {
	const selectButton = document.querySelector('.dropdown-custom__button');
	const sortList = document.querySelector('.dropdown-custom__list');
	const sortListCss = '.dropdown-custom__list';
	const listItem = document.querySelector('.dropdown-custom__list-item');
	const dropdown = document.querySelector('.dropdown-custom');

	document.addEventListener('click', event => handleClick(event, selectButton, sortList, listItem, sortListCss));
	if (dropdown) {
		let selectedResultIndex = -1;
		dropdown.addEventListener('keydown', (event) => {
			selectedResultIndex = handleKeyNavigation(event, dropdown, sortList, selectButton, selectedResultIndex);
		});
	}
}

function handleClick(e, btn, list, item, cssName) {
	if (!btn) return null;
	const targetedValue = e.target;
	const dropdown = document.querySelector('.dropdown-custom');
	const children = list.childNodes;

	if (dropdown && !dropdown.contains(e.target)) {
		list.classList.add(('dropdown-custom__hidden'));
		btn.setAttribute('aria-expanded', false);
		return null;
	}

	if (!btn.contains(targetedValue)) {
		btn.innerHTML = targetedValue.innerHTML;
	}
	toggleAriaExpanded(btn);
	for (let i = 0; i < children.length; i++) {
		if (children[i].classList && children[i].classList.contains('selected-option')) {
			toggleSelection(children[i]);
		}
	}
	toggleClassOnElementSelector('dropdown-custom__hidden', cssName);
	toggleSelection(e.target);
	return e;
}

(() => {
	setupSortListListeners();
})();
