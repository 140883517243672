import { get } from './common/index.es6';

(() => {
	lazyLoadRecommenderPart();
})();

function lazyLoadRecommenderPart() {
	const recommendedContentElements = [...document.querySelectorAll('.o-recommended-content')];

	recommendedContentElements.forEach((elem) => {
		const dataLoaded = elem.getAttribute('data-loaded');
		const partUrl = elem.getAttribute('data-part-url');
		if (dataLoaded === 'false') {
			const fetchPart = get(`${partUrl}?loadContent=true`);
			fetchPart
				.then(response => response.text())
				.then((data) => {
					elem.outerHTML = data;
				})
				.catch(() => {});
		}
	});
}
