(() => {
	const bannerButtons = document.getElementsByClassName('m-banner-arrow_down');
	if (bannerButtons) {
		Array.from(bannerButtons).forEach((bannerBtn) => {
			const container = bannerBtn.parentElement;
			bannerBtn.addEventListener('click', () => {
				window.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
			});
		});
	}
})();
