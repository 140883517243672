function toggleClass(el, className) {
	if (el.classList) {
		el.classList.toggle(className);
	} else {
		const classes = el.className.split(' ');
		const existingIndex = classes.indexOf(className);

		if (existingIndex >= 0) {
			classes.splice(existingIndex, 1);
		} else {
			classes.push(className);
		}
		el.className = classes.join(' ');
	}
}

exports.headerMenu = () => {
	const headerElement = document.querySelectorAll('.o-kitchen-header')[0];
	setupMenu(headerElement);
	setupUser(headerElement);
	setupFilter(headerElement);
};

function setupMenu(headerElement) {
	const menuButton = headerElement.querySelector('.o-kitchen-header__hamburger');
	if (!menuButton || !headerElement) {
		return;
	}

	const menu = headerElement.querySelector('.o-kitchen-header--menu');
	const menuButtonIcon = menuButton.querySelector('.a-icon-menu');
	const menuButtonClose = menuButton.querySelector('.a-icon-close');
	const submenuButtons = menu.querySelectorAll('.o-kitchen-header--menu__link');
	const submenus = menu.querySelectorAll('.o-kitchen-header--sub-menu');

	menuButton.addEventListener('click', () => {
		toggleClass(menuButtonIcon, 'hidden');
		toggleClass(menuButtonClose, 'hidden');
		toggleClass(menuButton, 'o-kitchen-header__cross');
		toggleClass(menu, 'hidden');
		handleClickOutside(headerElement.querySelector('.o-kitchen-header--menu__list'), menuButton, () => closeMenu(headerElement));
	});

	[...submenuButtons].forEach((element, i) => {
		element.addEventListener('click', () => {
			toggleClass(submenus[i], 'hidden');
			const arrow = element.querySelector('.arrow-icon');
			toggleClass(arrow, 'a-icon-arrow_down');
			toggleClass(arrow, 'a-icon-arrow_up');
			toggleClass(element, 'expanded-false');
			toggleClass(element, 'expanded-true');
		});
	});
}

function setupUser(headerElement) {
	const userButton = headerElement.querySelector('.o-kitchen-header__user');
	if (!userButton || !headerElement) {
		return;
	}
	const userButtonIcon = userButton.querySelector('.a-icon-user');
	const userButtonClose = userButton.querySelector('.a-icon-close');
	const userMenu = headerElement.querySelector('.o-kitchen-header--user');
	handleClickOutside(headerElement.querySelector('.o-kitchen-header--user__list'), userButton, () => closeUserMenu(headerElement));

	userButton.addEventListener('click', () => {
		toggleClass(userButtonIcon, 'hidden');
		toggleClass(userButtonClose, 'hidden');
		toggleClass(userButton, 'o-kitchen-header__cross');
		toggleClass(userMenu, 'hidden');
	});
}

function setupFilter(headerElement) {
	const filterButton = headerElement.querySelector('.o-kitchen-header--elements__search__button');
	if (!filterButton || !headerElement) {
		return;
	}
	const filterMenu = headerElement.querySelector('.o-kitchen-header--filter');
	const filterButtonIconUp = filterButton.querySelector('.a-icon-arrow_up');
	const filterButtonIconDown = filterButton.querySelector('.a-icon-arrow_down');

	filterButton.addEventListener('click', () => {
		toggleClass(filterButtonIconUp, 'hidden');
		toggleClass(filterButtonIconDown, 'hidden');
		toggleClass(filterMenu, 'hidden');
	});
}

function closeMenu(headerElement) {
	const menuElement = headerElement.querySelector('.o-kitchen-header--menu');
	if (menuElement.className.indexOf('hidden') === -1) {
		menuElement.classList.add('hidden');
		const menuButton = headerElement.querySelector('.o-kitchen-header__hamburger');
		const menuButtonIcon = menuButton.querySelector('.a-icon-menu');
		const menuButtonClose = menuButton.querySelector('.a-icon-close');
		toggleClass(menuButtonIcon, 'hidden');
		toggleClass(menuButtonClose, 'hidden');
	}
}

function closeUserMenu(headerElement) {
	const userElement = headerElement.querySelector('.o-kitchen-header--user');
	if (userElement.className.indexOf('hidden') === -1) {
		userElement.classList.add('hidden');
		const userButton = headerElement.querySelector('.o-kitchen-header__user');
		const userButtonIcon = userButton.querySelector('.a-icon-user');
		const userButtonClose = userButton.querySelector('.a-icon-close');
		toggleClass(userButtonIcon, 'hidden');
		toggleClass(userButtonClose, 'hidden');
	}
}

function handleClickOutside(domElement, triggerButton, callback) {
	document.addEventListener('click', (event) => {
		const { target } = event;
		if (target !== triggerButton && !triggerButton.contains(target) && target !== domElement && !domElement.contains(target)) {
			callback();
		}
	});
}
