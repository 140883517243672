import { addRating } from './common/index.es6';
import { showMessage } from './message-box.es6';

(() => {
	const ratingSection = document.getElementById('rating-modal-content');
	const ratingButtons = document.getElementsByClassName('o-sentimant--rating-btn');
	if (ratingButtons) {
		Array.from(ratingButtons).forEach((ratingButton) => {
			ratingButton.addEventListener('click', getRatingListener(ratingButton));
			ratingButton.addEventListener('mouseenter', getMouseOverListener(ratingButton));
			ratingButton.addEventListener('mouseleave', onMouseOut);
		});
	}

	function getRatingListener(ratingButton) {
		return () => {
			optimisticResponseRating(ratingButton, true);
			const displayName = document.getElementsByClassName('t-heading--tinekjokken')[0].textContent;
			const rating = ratingButton.dataset.score;
			addRating(ratingButton, rating)
				.then(() => {
					disableRatingSection();
					showMessage('rate', displayName);
					if (ratingSection) {
						ratingSection.addEventListener('click', () => {
							showMessage('alreadyRated', displayName);
						});
					}
				})
				.catch(() => {
					optimisticResponseRating(ratingButton, false);
					showMessage('error');
				});
		};
	}

	function disableRatingSection() {
		Array.from(ratingButtons).forEach(btn => btn.setAttribute('disabled', 'disabled'));
	}

	function getMouseOverListener(ratingButton) {
		return () => {
			const score = parseInt(ratingButton.dataset.score, 10);
			ratingSection.className = ratingSection.className.replace(/(rated-[0-5])/g, `rated-${score}`);
		};
	}

	function onMouseOut() {
		ratingSection.className = ratingSection.className.replace(/(rated-[0-5])/g, `rated-${ratingSection.dataset.score}`);
	}
})();


function optimisticResponseRating(ratingButton, boolean) {
	const ratingSection = document.getElementById('rating-modal-content');
	const ratingButtonCount = document.getElementById('RatingButtonCount');
	const ratingScore = parseInt(ratingButton.dataset.score, 10);
	const ratingAverageScore = parseFloat(ratingButtonCount.dataset.ratingaverage);
	const ratingTotalVotes = parseInt(ratingButtonCount.innerHTML, 10);

	if (boolean) {
		const temp = ((ratingTotalVotes * ratingAverageScore) + ratingScore) / (ratingTotalVotes + 1);
		const newRatingAverageScore = Math.round(temp * 10) / 10;

		ratingButtonCount.innerHTML = (parseInt(ratingButtonCount.innerHTML, 10) + 1).toString();

		if (newRatingAverageScore !== ratingAverageScore) {
			ratingSection.className = ratingSection.className.replace(/(rated-[0-5])/g, `rated-${newRatingAverageScore}`);
		}
	} else {
		ratingSection.className = ratingSection.className.replace(/(rated-[0-5])/g, `rated-${ratingAverageScore}`);
		ratingButtonCount.innerHTML = (parseInt(ratingButtonCount.innerHTML, 10) - 1).toString();
	}
}
