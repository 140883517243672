export const COOKIES = {
	TK_USER_ID: 'tkUserId'
};

export function parseCookieString(cookieStr) {
	const output = {};
	cookieStr.split(/\s*;\s*/).forEach((pair) => {
		const tempPair = pair.split(/\s*=\s*/);
		output[tempPair[0]] = tempPair.splice(1).join('=');
	});
	return output;
}

export function getCookie(name) {
	return parseCookieString(window.document.cookie)[name];
}

