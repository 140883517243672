import { parseCookieString, addOrRemoveFavorite } from './common/index.es6';
import { showMessage } from './message-box.es6';

(() => {
	const favoriteButtons = document.getElementsByClassName('m-recipe-card--favourite__button');
	if (favoriteButtons) {
		Array.from(favoriteButtons).forEach(favoriteButton => favoriteButton.addEventListener('click', () => {
			const cookies = parseCookieString(window.document.cookie);
			if (cookies.tineSSOSession) {
				optimisticResponseFavorite(favoriteButton);
				addOrRemoveFavorite(favoriteButton)
					.then(() => {
						const displayName = favoriteButton.dataset.title;
						if (favoriteButton.dataset.favorited === 'true') {
							showMessage('favorite', displayName);
						} else {
							showMessage('unfavorite', displayName);
						}
					})
					.catch(() => {
						optimisticResponseFavorite(favoriteButton);
						showMessage('error');
					});
			} else {
				showMessage('login');
			}
		}));
	}
})();

function optimisticResponseFavorite(favoriteButton) {
	if (favoriteButton) {
		const favoriteButtonCount = favoriteButton.getElementsByClassName('o-sentiment--favorite__count').item(0);
		const favoriteButtonIcon = favoriteButton.getElementsByClassName('a-icon').item(0);
		if (favoriteButtonIcon && favoriteButtonIcon.classList) {
			favoriteButtonIcon.classList.toggle('a-icon-Heart');
			favoriteButtonIcon.classList.toggle('a-icon-heart');
		}

		if (favoriteButton.dataset.favorited === 'true') {
			favoriteButton.dataset.favorited = 'false';
			if (favoriteButtonCount) {
				favoriteButtonCount.innerHTML = (parseInt(favoriteButtonCount.innerHTML, 10) - 1).toString();
			}
		} else {
			favoriteButton.dataset.favorited = 'true';
			if (favoriteButtonCount) {
				favoriteButtonCount.innerHTML = (parseInt(favoriteButtonCount.innerHTML, 10) + 1).toString();
			}
		}
	}
}
