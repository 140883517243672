export function showMessage(key, displayName = 'Oppskriften') {
	const messages = {
		rate: {
			content: `Takk for din vurdering av ${displayName}!`,
			type: 'success'
		},
		alreadyRated: {
			content: `Du har allerede gitt "${displayName}" en vurdering!`,
			type: 'error'
		},
		favorite: {
			content: `"${displayName}" er lagt til i dine favoritter`,
			type: 'success'
		},
		unfavorite: {
			content: `"${displayName}" er fjernet fra dine favoritter`,
			type: 'success'
		},
		login: {
			content: `<a class="m-message-box__link" href="https://sso.tine.no/publicuser/forms/login?redir=${window.location.href}">Logg inn</a> for å lagre favoritter`,
			type: 'error'
		},
		nocomment: {
			content: 'Fyll ut feltene for å sende inn en kommentar',
			type: 'error'
		},
		error: {
			content: 'Det har oppstått en feil',
			type: 'error'
		}
	};

	if (messages[key]) {
		const content = messages[key].content || null;

		const DURATION = 3500;
		const $ = document.querySelector.bind(document);
		const message = $('.m-message-box');

		message.innerHTML = `${content}`;
		message.classList.toggle(`${messages[key].type}-message`);

		setTimeout(() => message.classList.toggle(`${messages[key].type}-message`), DURATION);
	}
}
