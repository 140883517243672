export const fetch = function fetch(url, options = {}) {
	return window.fetch(url, options)
		.then((response) => {
			if (response.status >= 400) {
				throw new Error(response);
			}
			return response;
		});
};

export function get(url, body, options = {}) {
	return fetch(url, {
		method: 'GET',
		body,
		...options
	});
}

export function post(url, body, options = {}) {
	return fetch(url, {
		method: 'POST',
		...options,
		body
	});
}
