import { post } from './fetch.es6';

const { tineAPI } = window;

export const BASE_HEADERS = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Headers': 'authorization, content-type'
};

function postTineApi(url, body) {
	return post(`${url}?siteKey=${tineAPI.tineSiteId}`, JSON.stringify(body), {
		method: 'POST',
		credentials: 'include',
		headers: BASE_HEADERS
	});
}

export function addOrRemoveFavorite(favoriteButton) {
	const url = favoriteButton.dataset.favorited === 'false' ? tineAPI.favoriteRemove : tineAPI.favoriteAdd;
	return postTineApi(url, {
		queries: [
			{ contentKey: favoriteButton.dataset.contentkey }
		]
	});
}

export function addRating(ratingButton, rating) {
	return postTineApi(tineAPI.ratingAdd, {
		queries: [
			{
				contentKey: ratingButton.dataset.contentkey,
				contentType: ratingButton.dataset.variant,
				rating
			}
		]
	});
}
